import * as React from "react"
import { PrismicNavigation } from "../prismic/layout/PrismicNavigation"
import Layout from "../prismic/layout"
import { Col, Container, Row } from "./Grid/Grid"
import PrismicFooter from "../prismic/layout/PrismicFooter"

const PageWithoutHero = ({children}) => (
  <Layout>
    <PrismicNavigation lang={"en-ca"}/>
    <Container>
      <Row style={{ paddingTop: 200, paddingBottom: 100 }}>
        <Col col={12} xl={10} style={{ margin: "0 auto", padding: 16 }}>
          {children}
        </Col>
      </Row>
    </Container>
    <PrismicFooter lang={"en-ca"}/>
  </Layout>
)

export default PageWithoutHero
