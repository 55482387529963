import * as React from "react"
import PageWithoutHero from "../components/PageWithoutHero"
import styled from "styled-components"
import { Link } from "gatsby"
import {primaryStyles, baseStyles} from "../components/Button"
import { navigate } from "gatsby-link"

const StyledSubmit = styled.input`
  ${baseStyles}
  ${primaryStyles}
`

const MyBuild = (props) => {
  const [code, setCode] = React.useState('');

  return (
    <PageWithoutHero>
      <h2>Make your build selections</h2>
      <p>Enter your code below to be taken to your build selection form. If you are unsure of your
        build code please <Link to={"/contact"}>contact us</Link>.</p>
      <form action={`/accounts/${code}/build-selections/`} onSubmit={(e) =>  {
        e.preventDefault();
        navigate(e.target.getAttribute("action"))
      }}>
        <div style={{marginBottom: 16}}>
        <input type={"text"} name={"code"} placeholder={"Build Code"} onInput={(e) => setCode(e.target.value.toUpperCase())} />
        </div>
        <div>
        <StyledSubmit type={'submit'} value={'Submit'} />
        </div>
      </form>
    </PageWithoutHero>
  )
}

export default MyBuild
